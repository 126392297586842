import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import VueSimpleAlert from "vue-simple-alert"
import VueRouter from 'vue-router'
import { store } from "./store";
import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import VueMask from 'v-mask';
//import axios from 'axios';




import LoginComponent from './pages/Login/LoginComponent.vue'
import HomeComponent from './pages/Home/HomeComponent.vue'
import Producao from './pages/Distribuidores/ProducaoMensagemComponent.vue'
// import ListaDistribuidoresComponent from './pages/Distribuidores/ListaDistribuidoresComponent.vue'
import UsuarioComponent from './pages/Usuarios/UsuarioComponent.vue'
// import RelatoriosMenuComponent from './pages/Relatorios/RelatoriosMenuComponent.vue'
import CadastroUsuarioComponent from './pages/Usuarios/CadastroUsuarioComponent.vue'
// import ListaGeralDistribuidoresComponent from './pages/Relatorios/ListaGeralDistribuidoresComponent.vue'
// import ListaGeralClientesDistribuidoresComponent from './pages/Relatorios/ListaGeralClientesDistribuidoresComponent.vue'
// import ListaClientesDistribuidorComponent from './pages/Relatorios/ListaClientesDistribuidorComponent.vue'
// import ListaGeralUsuariosComponent from './pages/Relatorios/ListaGeralUsuariosComponent.vue'
//import EditDistribuidorComponent from './pages/Distribuidores/EditDistribuidorComponent.vue'
import EditUsuarioComponent from './pages/Usuarios/EditUsuarioComponent.vue'
import TotalAtivosComponent from './pages/Distribuidores/TotalDistribuidoresAtivosComponent.vue'

import ClientesMensagemComponent from './pages/Distribuidores/ClientesMensagemComponent.vue'

import Concluido from './pages/Distribuidores/ConcluidoMensagemComponent.vue'
import Analise from './pages/Distribuidores/AnaliseMensagemComponent.vue'


import Termos from './pages/ProtecaoDados/TermoProtecaoDados.vue'
// import PedidosComponent from './pages/Pedidos/PedidosComponent.vue'





Vue.config.productionTip = false
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(VueMask);
Vue.use(VueSimpleAlert);



// let isAlreadyFetchingAccessToken = false
// let subscribers = []

// function onAccessTokenFetched(access_token) {
// subscribers = subscribers.filter(callback => callback(access_token))
// }

// function addSubscriber(callback) {
// subscribers.push(callback)
// }

// axios.interceptors.response.use(function (response) {
// return response
// }, function (error) {
// const { config, response: { status } } = error
// const originalRequest = config

// if (status === 401) {
//     if (!isAlreadyFetchingAccessToken) {
//     isAlreadyFetchingAccessToken = true
//     store.dispatch().then((access_token) => {
//         isAlreadyFetchingAccessToken = false
//         onAccessTokenFetched(access_token)
//     })
//     }

//     const retryOriginalRequest = new Promise((resolve) => {
//     addSubscriber(access_token => {
//         originalRequest.headers.Authorization = 'Bearer ' + access_token
//         resolve(axios(originalRequest))
//     })
//     })
//     return retryOriginalRequest
// }
// return Promise.reject(error)
// })



function guardMyroute(to, from, next) {
    var isAuthenticated = false;
    //this is just an example. You will have to find a better or 
    // centralised way to handle you localstorage data handling 
    if (localStorage.getItem('token'))
        isAuthenticated = true;
    else
        isAuthenticated = false;
    if (isAuthenticated == true) {
        next(); // allow to enter route
    } else {
        next("/login"); // go to '/login';
    }
}


const routes = [
    { path: "/", name: 'login', component: LoginComponent },
    { path: "/login", name: 'login', component: LoginComponent },
    { path: '/Dashboard', name: 'home', component: HomeComponent, beforeEnter: guardMyroute },
    //{ path: '/distribuidores', name: 'listadistribuidores', component: ListaDistribuidoresComponent, beforeEnter: guardMyroute },

    // { path: '/clientes', name: 'clientes', component: ClienteComponent },

    //{ path: '/distribuidores/editar/:id', name: 'editarDistribuidor', component: EditDistribuidorComponent, params: true, beforeEnter: guardMyroute },
    { path: '/usuarios', name: 'usuarios', component: UsuarioComponent, beforeEnter: guardMyroute },
    { path: '/usuarios/cadastrar', name: 'cadastrousuarios', component: CadastroUsuarioComponent, beforeEnter: guardMyroute },
    // { path: '/relatoriosmenu', name: 'relatoriosmenu', component: RelatoriosMenuComponent, beforeEnter: guardMyroute },
    // { path: '/relatorio/distribuidores/geral', name: 'listageraldistribuidores', component: ListaGeralDistribuidoresComponent, beforeEnter: guardMyroute },
    // { path: '/relatorio/clientes', name: 'listageralclientesdistribuidores', component: ListaGeralClientesDistribuidoresComponent, beforeEnter: guardMyroute },
    // { path: '/relatorio/clientes/byDistribuidor', name: 'listaclientesdistribuidor', component: ListaClientesDistribuidorComponent, beforeEnter: guardMyroute },
    // { path: '/listageralusuarios', name: 'listageralusuarios', component: ListaGeralUsuariosComponent, beforeEnter: guardMyroute },
    { path: '/usuarios/editar/:id', name: 'editusuario', component: EditUsuarioComponent, params: true, beforeEnter: guardMyroute },
    { path: '/distribuidoresAtivos', name: 'ativostotal', component: TotalAtivosComponent, beforeEnter: guardMyroute },

    { path: '/mensagens/producao', name: 'producao', component: Producao, beforeEnter: guardMyroute },
    { path: '/mensagens/analise', name: 'analise', component: Analise, beforeEnter: guardMyroute },
    { path: '/mensagens/pendentes', name: 'clientesMensagens', component: ClientesMensagemComponent, beforeEnter: guardMyroute },
    { path: '/mensagens/concluido', name: 'concluido', component: Concluido, beforeEnter: guardMyroute },
    // { path: '/pedidos', name: 'pedidos', component: PedidosComponent, beforeEnter: guardMyroute },

    { path: "/termos-protecao-de-dados", name: 'termos', component: Termos},

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes // short for `routes: routes`

})

new Vue({
    router,
    store: store,
    render: h => h(App),
}).$mount('#app')