<template>
  <DashboardComponent>
    <div slot="slot-pages" class="content-pages">
      <article>
        <div class="container-fluid">
          <br />
          <h1>Lista Distribuidores Ativos Aplicativo</h1>
          <br />

          <div class="row">
            <!--BUTTONS DE IMPORT E NOVO CADASTRO-->
            <div class="col"></div>
            <div class="col"></div>
            <div class="col">
              <button
                type="button"
                class="buttonImprimir"
                @click="generatePDF()"
              >
                Imprimir
              </button>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col">
              <select
                class="form-select"
                aria-label="Default select example"
                v-model="selecionaEmpresa"
                @click="controllerClienteEmpresa()"
              >
                <option disabled value="">Selecione um Empresa</option>
                <option value="1">All-in Brasil</option>
              </select>
            </div>
            <div class="col"></div>
            <div class="col"></div>
            <div class="col"></div>
          </div>
          <br />
          <div v-if="check">
            <div class="row">
              <div class="col">
                <div class="form-group input-group">
                  <input
                    name="consulta"
                    id="filterbox"
                    placeholder="Pesquisar"
                    type="search"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  v-model="selecionaModo"
                  @click="controllerClienteModo()"
                >
                  <option disabled value="">Selecione um Modo</option>
                  <option value="1">Todos</option>
                  <option value="2">A Cobrar</option>
                  <option value="3">Vencimento hoje</option>
                </select>
              </div>
              <div class="col"></div>
              <div class="col">
                <input
                  class="form-select"
                  id="date"
                  type="date"
                  v-model="dataInicio"
                />
              </div>
              <div class="col">
                <input
                  class="form-select"
                  id="date"
                  type="date"
                  v-model="dataFinal"
                />
              </div>
              <div class="col">
                <button class="buttonFiltrar" @click="filtroData()">
                  Filtrar
                </button>
              </div>
            </div>

            <br />
            <div class="row">
              <div class="col titulo">
                <h3>Distribuidores Ativos App {{ tituloPagina }}</h3>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table" id="geral">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">Tipo</th>
                    <th scope="col">Nome</th>
                    <th scope="col">Usuario Maxinivel</th>
                    <th scope="col">Cidade</th>
                    <th scope="col">UF</th>
                    <th scope="col">Telefone</th>
                    <th scope="col">Data Ativação</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
          </div>
          <div v-else>
            <div class="row">
              <div class="col"></div>
              <div class="col"><Spinner /></div>
              <div class="col"></div>
            </div>
          </div>
        </div>
      </article>
    </div>
    <link
      rel="stylesheet"
      type="text/css"
      href="//cdn.datatables.net/1.10.13/css/jquery.dataTables.css"
    />
    <link
      rel="stylesheet"
      href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css"
    />
  </DashboardComponent>
</template>

<script>
import DashboardComponent from "../Dashboard/Dashboard.vue";
//import {mapState, mapActions} from 'vuex';
//import { http } from "../../../http/index";
import { Allin } from "../../../http/index";
const { jsPDF } = require("jspdf");
import "jspdf-autotable";
import Spinner from "../Dashboard/components/Spinner.vue";
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
export default {
  name: "TotalAtivosComponent",
  components: {
    DashboardComponent,
    Spinner,
  },
  data() {
    return {
      titulo: "Lista Distribuidores Ativos Aplicativo",
      filterDistri: [],
      filterTotal: [],
      distriAtivos: [],
      filterModo: [],
      selecionaEmpresa: "",
      selecionaModo: "",
      selecionaTipo: "",
      tituloPagina: "",
      dataInicio: "",
      dataFinal: "",
      check: false,
    };
  },

  computed: {
    //...mapState("distribuidores", ["distribuidoresAtivosTotal"]),
  },
  methods: {
    //...mapActions("distribuidores", ["getDistribuidoresAtivosTotal"]),

    dataAtual() {
      var data = new Date();
      var dia = String(data.getDate()).padStart(2, "0");
      var mes = String(data.getMonth() + 1).padStart(2, "0");
      var ano = data.getFullYear();
      var dataAtual = `${dia}-${mes}-${ano}`;
      //console.log(dataAtual);
      return dataAtual;
      //onsole.log(dia);
    },

    controllerClienteEmpresa() {
      this.check = false;

      if (this.selecionaEmpresa == 1) {
        this.tituloPagina = "All-in Brasil";
        this.getAppUserAllin();
        this.check = true;
      }
    },

    controllerClienteModo() {
      this.filterDistri = [];
      this.filterModo = [];

      if (this.selecionaModo == 1) {
        this.filterDistri = this.filterTotal;
        this.filterModo = this.filterTotal;
        this.teble();
      }
      //console.log(this.filterDistri);

      if (this.selecionaModo == 2) {
        for (let i = 0; i < this.filterTotal.length; i++) {
          if (i > 49) {
            this.filterDistri.push(this.filterTotal[i]);
            this.filterModo.push(this.filterTotal[i]);
          }
        }

        this.teble();
        //console.log(this.filterDistri);
      }

      if (this.selecionaModo == 3) {

        var dataAtual = this.dataAtual();
        const divisao = dataAtual.split("-");
        const diaMes = `${divisao[0]}-${divisao[1]}`;

        //console.log(diaMes);
        //console.log(this.filterTotal);

        for (let i = 0; i < this.filterTotal.length; i++) {
          if (i > 49) {
            let corta = this.filterTotal[i].create_data.split("-");
            const sDiaMes = `${corta[0]}-${corta[1]}`;
            //console.log(diaMes)
            //console.log(sDiaMes)

            if (divisao[2] != corta[2]) {
              if (diaMes == sDiaMes) {
                this.filterDistri.push(this.filterTotal[i]);
                this.filterModo.push(this.filterTotal[i]);
              }
            }
          }
        }

        this.teble();
      }
    },
    filtroData() {
      //this.ControllerClienteModo();
      //console.log(this.dataInicio);
      //console.log(this.dataFinal);

      let dInicio = this.arumaData(this.dataInicio);
      let dFinal = this.arumaData(this.dataFinal);

      this.converteData(dFinal);
      this.converteData(dInicio);

      let dataInicial = this.converteData(dInicio);
      let dataFinal = this.converteData(dFinal);
      let objetosFiltrados = this.filterModo.filter((result) => {
        return (
          this.converteData(result.create_data) >= dataInicial &&
          this.converteData(result.create_data) <= dataFinal
        );
      });
      this.filterDistri = objetosFiltrados;
      this.teble();
    },

    converteData(DataDDMMYY) {
      //console.log(DataDDMMYY);
      const dataSplit = DataDDMMYY.split("-");
      const novaData = new Date(
        parseInt(dataSplit[2], 10),
        parseInt(dataSplit[1], 10) - 1,
        parseInt(dataSplit[0], 10)
      );
      return novaData;
    },
    arumaData(data) {
      let divisao = data.split("-");
      let dataArumada = `${divisao[2]}-${divisao[1]}-${divisao[0]} `;
      return dataArumada;
    },

    generatePDF() {
      const doc = new jsPDF("p", "pt", "a4");
      doc.text(this.titulo, 15, 15);
      doc.autoTable({ html: "#geral" });
      doc.save("Distribuidores_Ativos_Aplicativo.pdf");
    },

    filterDistribuidores() {
      this.filterDistri = [];

      this.distriAtivos.forEach((distribuidor) => {
        while (!distribuidor.id_maxinivel) {
          distribuidor.id_maxinivel = "Não informado";
        }
        if (distribuidor.id_maxinivel.includes("excluido")) {
          distribuidor.id_maxinivel = "Excluido";
        }
        if (!distribuidor.celular) {
          distribuidor.celular = "Não informado";
        }
        if (!distribuidor.nome) {
          distribuidor.nome = "Não informado";
        }
        if (!distribuidor.tipo) {
          distribuidor.tipo = "Indeterminavel";
        }
        if (!distribuidor.uf) {
          distribuidor.uf = "Não informado";
        }
        if (!distribuidor.descricao) {
          distribuidor.descricao = "Não informado";
        }

        let corte = distribuidor.create_data.split(" ");
        let divisao = corte[0].split("-");
        distribuidor.create_data = `${divisao[2]}-${divisao[1]}-${divisao[0]}`;

        this.filterDistri.push(distribuidor);
        this.filterTotal.push(distribuidor);
      });
      //console.log(this.filterDistri);
    },
    teble() {
      $.noConflict();
      var table = $("#geral").DataTable({
        stateSave: false,
        bDestroy: true,
        scrollCollapse: true,
        data: this.filterDistri,
        columns: [
          { data: "tipo" },
          { data: "nome" },
          { data: "id_maxinivel" },
          { data: "descricao" },
          { data: "uf" },
          { data: "celular" },
          { data: "create_data" },
        ],
        processing: true,
        language: {
          url: "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Portuguese-Brasil.json",
        },
        //Tira a funçao de ordenar de uma coluna
        //tira o input padrao de busca do dataTable
        dom: "lrtip",
      });
      //Coloca a funçao de filtro (busca) em um input personalizado
      $("#filterbox").on("keyup", function () {
        table.search(this.value).draw();
      });
    },

    getAppUserAllin() {
      this.distriAtivos = [];
      Allin.get("appUser/all").then((response) => {
        this.distriAtivos = response.data;
        //console.log(this.distriAtivos);
        this.filterDistribuidores();
      });
    },
  },
  created() {
    this.dataAtual();
  },
  mounted() {
    //this.getDistribuidoresAtivosTotal();
  },
};
</script>


<style scoped>
.content-pages {
  padding: 1px;
}
article {
  background-color: #1f1f1f;
  height: 100%;
}
e {
  visibility: hidden;
}
h1 {
  text-align: center;
}
.titulo {
  text-align: center;
}
td {
  background-color: #1f1f1f;
  color: white;
}
input[type="text"] {
  border: 1px solid black;
  border-radius: 4px;
}
thead {
  font-size: 18px;
  text-align: center;
  background-color: rgb(156, 153, 153);
}
tbody {
  font-size: 17px;
  text-align: center;
}
.btn btn-success {
  background-color: orange;
}

.buttonImprimir {
  height: 40px;
  width: 100%;
  border-radius: 4px;
  background-color: rgb(67, 192, 255);
  color: white;
  border: none;
  padding: 5px;
}
.buttonFiltrar {
  height: 40px;
  width: 100%;
  border-radius: 4px;
  background-color: rgb(158, 188, 35);
  color: white;
  border: none;
  padding: 5px;
}

@media (max-width: 1100px) {
  h1 {
    text-align: center;
    font-size: 25px;
  }
  .buttonImprimir {
    height: 40px;
    width: 100%;
    border-radius: 4px;
    background-color: rgb(67, 192, 255);
    color: white;
    border: none;
    padding: 5px;
  }
}
</style>