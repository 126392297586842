<template>
    
    <div class="main">
        
        <div class="sidebar">
            <SidebarComponent/>
            
        </div>
        <div class="content-right">
            <div class="navbar">
                <NavbarComponent/>
                
            </div>
            <div class="spinner">
                <SpinnerComponent v-if="showLoading"> </SpinnerComponent>
            </div>
            <div class="pages">
                
                <slot name="slot-pages">
                </slot>
                 
            </div>
        </div>

    </div>
</template>

<script>
import SidebarComponent from './components/SidebarComponent.vue'
import NavbarComponent from './components/NavbarComponent.vue'
import SpinnerComponent from './components/Spinner.vue'
import { mapState } from 'vuex'

export default {
    name: 'DashboardComponent',
    components: {
        SpinnerComponent,
        SidebarComponent,
        NavbarComponent
    },
    computed:{
        ...mapState("showLoading", ["showLoading"]),

    }
}
</script>

<style src='./style.css' scoped>


</style>