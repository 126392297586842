<template>
    <body>
        <div class="wrapper fadeInDown">
            <div id="formContent">

                <!-- Icon CRM WIN -->
                <div class="fadeIn first">
                    <img src="@/assets/images/logo2.png" id="icon">
                </div>

                <!-- Formulario de Login -->
                <form @submit.prevent="efetuarLogin">
                    <div class="form.group">
                        <input type="text" id="login" class="fadeIn second" name="login" placeholder="usuario" v-model="usuario.usuario"/>
                    </div>
                    <div class="form.group">
                        <input type="password" id="password" class="fadeIn second" name="login" placeholder="senha"  v-model="usuario.password"/>
                    </div>
                    <br>
                    <button type='submit'   class="button">Logar</button>
                </form>
                <br>

            <!-- Relembrar Senha -->
           
        </div>
        </div>
    </body>
</template>
<script>
//import axios from 'axios';
import login from '../../../services/Login'
export default {
    name: 'LoginComponent',

    data(){
        return {
            validation: {
                'client_id': 'ALLINCRMW_8ebae0074',
                'client_secret' : '6c441e81fa1b80e80fb7128690ae1844f7e82487',
                'grant_type' : 'client_credentials',
            },
            usuario : {
                'usuario' : '',
                'password' : ''
            },
        }
    },

    methods: {
        
       efetuarLogin(){
         login.login(this.usuario).then(response =>{
             
             localStorage.setItem('token', response.data.access_token)
             //this.tokenMaxinivel();
             this.$router.push({name: 'home'});
         
        })
          .catch(erro => console.log(erro))

       },

        // LoginTeste(){
        //     axios.get('https://api.ecoteste.crmwin.eco.br/public/api/login', this.usuario)
        //    .then(response => {
        //         localStorage.setItem('token', response.data.access_token)
        //       console.log(response.data.access_token);
        //       console.log('testanto');
        //    }).catch(erro => console.log (erro))
        // },


    //    tokenMaxinivel(){
    //        axios.post('https://allinbrasil.com.br/api/v1/auth/token', this.validation)
    //        .then(response => {
    //            if(response.status == 200 ){
    //              localStorage.setItem('tokenMax', response.data.access_token)
               
    //            }
    //        }).catch(erro => console.log (erro))
    //      },

    }
};
</script>

<style src='./style.css' scoped>
  

</style>