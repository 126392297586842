import { http } from "../http/index";



export default{
    listar:()=>{
        return http.get('distribuidores')
    },
    salvar:(distribuidor)=>{
        return http.post('distribuidores' , distribuidor)
    },
    byId:(id_distribuidor)=>{
        return http.post('distribuidores/id' , id_distribuidor)
    },
    update:(distribuidor)=>{
        return http.put('distribuidores', distribuidor)
    },
    inative:(id)=>{
        return http.get(`distribuidor/inative/${id}`)
    },
    mensal:()=>{
        return http.get('distribuidores/mensal')
    },
    distribuidoresAtivos:()=>{
        return http.get('appuser/getAll')
    },
    ativos:()=>{
        return http.get('appuser/count')
    },
    byUf:(uf)=>{
        return http.get(`distribuidores/${uf}`)
    },
    editar:(distribuidor)=>{
        return http.put('distribuidores', distribuidor)
    },
    import:(maxinivelDistri) =>{
        return http.post('distribuidoresMaxinivel', maxinivelDistri)
    } 
}