<template>
  <div class="tudo">
    <br />
    <div class="politica">
      <h1>ACCEPTANCE AGREEMENT FOR THE PROCESSING AND USE OF PERSONAL DATA</h1>
      <br />

      <p>
        This document aims to register the free, informed and unambiguous
        expression of intent by which the user agrees to the treatment of their
        personal data for a specific purpose, in conformity with Brazilian Law
        no. 13.709/2020 - General Law for the Protection of Personal Data
        (LGPD).
        <br />
        By accepting this term, the USER consents and agrees that the company
        ECO SISTEMAS E SOLUÇÕES, registered under the CNPJ no.
        08.951.144/0001-35, headquartered at Rua Taquari, 370 – Zona Nova
        District – Capão da Canoa/RS, contact information: (51) 3625-3486 and
        (51) 9.9167-2281, electronic address diretoriaecosistemas@gmail.com,
        hereinafter referred to as CONTROLLER, carries out the treatment and use
        of their personal data, as specified in this agreement.
      </p>
      <br />

      <h2>1. Regarding the controller</h2>

      <p>
        The CONTROLLER, a legal entity governed by private law, will decide
        regarding the treatment of the user’s personal data, which will involve
        operations such as: collection, production, reception, classification,
        use, access, reproduction, transmission, distribution, processing,
        archiving, storing, deleting, evaluating or controlling data, as well as
        communicating, transferring, diffusing or extracting the information.
      </p>
      <br />

      <h2>2. Personal Data</h2>
      <p>
        The CONTROLLER is authorized to make decisions and to process the
        following personal data of the HOLDER:
      </p>
      <ul>
        <li>Full name</li>
        <li>Personal or professional email address;</li>
        <li>Phone number;</li>
        <li>Billing adress;</li>
        <li>Delivery adress;</li>
      </ul>
      <br />

      <h2>3. Purposes of Data Processing</h2>

      <p>
        The processing of personal data listed in this term has the following
        purposes:
      </p>

      <ul>
        <li>
          Enable the CONTROLLER to identify and contact the USER for customer
          relationship management and quality control purposes between the
          software, usage and user;
        </li>
        <li>
          Enable the CONTROLLER to give access to the
          <div class="sublinha">APP LOJA</div>
          system for importing and exporting data to the
          <div class="sublinha">PAGOZAPE</div>
          platform;
        </li>
        <li>
          Allow the monitoring of registrations made by the HOLDER and
          collaborate with whatever is necessary for data confidentiality,
          market research and for the data that are indispensable for the
          CONTROLLER to comply with legal or regulatory obligations;
        </li>
      </ul>

      <div class="negrito">First Section.</div>
      <p>
        The user is aware that the aforementioned purposes will not be treated
        in a way that is incompatible with what is described. New changes will
        only be carried out upon specific and express agreement of the USER,
        thus delimiting a new objective of data processing.
      </p>
      <br />

      <h2>4. Data Sharing</h2>

      <p>
        The CONTROLLER is authorized to share the HOLDER's personal data with
        other data processing agents, in this case with TECCASH, to meet the
        purposes listed in this agreement, subject to the principles and
        guarantees established by the Brazilian General Data Protection Law
        (LGPD).
        <br />
        The database registered by the HOLDER will not be shared with third
        parties with the purpose of marketing information for commercial use or
        for other purposes beyond the treatment and security of information
        provided for user registrations when they join the application.
      </p>
      <br />

      <h2>5. Data Security and Prevention</h2>

      <p>
        The Controller is responsible for maintaining security, technical and
        administrative measures capable of protecting personal data from
        unauthorized access and from accidental or unlawful situations of
        destruction, loss, alteration, communication or any form of
        inappropriate or unlawful treatment.<br />
        According to article 48 of the Brazilian Law No. 13.709/2020, the
        Controller will notify the HOLDER and the Autoridade Nacional de
        Proteção de Dados (ANPD) of the occurrence of a security incident that
        may cause relevant risk or damage to the Holder.
        <br />
        Attending to the principle of Prevention, the Controller will adopt data
        protection measures before, during and after the treatment, being its
        duty to look after the data before third parties who have access to the
        information registered by the USER with the application in question
      </p>
      <br />

      <h2>6. On Transparency, Open Access and Communication</h2>

      <p>
        The CONTROLLER must provide the user with objective, clear and succinct
        language regarding whatever is happening with their data to ensure easy
        access to the treatment of available information, as well as in this
        agreement to accept the conditions of use and privacy policies. The USER
        may contact the company at any time, thus avoiding offending data
        protection regulations.
      </p>
      <br />

      <h2>7. Rights of the Holder</h2>

      <p>
        The HOLDER has the right to obtain from the CONTROLLER, regarding the
        processed data, at any time and upon request: I - confirmation of the
        existence of treatment; II - access to data; III - correction of
        incomplete, inaccurate or outdated data; IV - anonymization, blocking or
        erasure of unnecessary, excessive or data that is processed in
        disagreement with the provisions of Law No. 13.709/2020; V - data
        portability to another service or product provider, upon express
        request, in accordance with the regulations of the national authority,
        observing commercial and industrial secrets; VI - erasure of personal
        data processed with the consent of the holder, except in the cases
        provided for in article 16 of Law No. 13.709/2020; VII - information on
        public and private entities with which the controller shared data; VIII
        - information on the possibility of not providing consent and on the
        consequences of denial; IX - withdraw of consent, pursuant to Section 5
        of article 8 of Law No. 13.709/2020.
      </p>
      <br />

      <h2>8. End of Data Processing</h2>

      <p>
        The CONTROLLER may keep and process the HOLDER's personal data during
        the entire period that the user is registered in the APP LOJA system
        relevant to the scope of the purposes listed in this agreement, after
        treatment, becoming inactive for the CONTROLLER.
        <br />
        The database operated by the USER throughout the use of the application
        will be retained with the CONTROLLER only as a source of record or even
        for reporting to the Agência Nacional de Proteção de Dados. These data
        may be kept for an indefinite period, without the possibility of
        association with the individual who lives up to the description, and in
        cases of association, the HOLDER may request the erasure of personal
        data information.
        <br />
        Since the purpose of the application is only to ensure the security,
        maintenance and organization of the administered data by the HOLDER, the
        HOLDER may request its base with the intention that it deems correct for
        maintenance, exempting the CONTROLLER from any negligence, imprudence or
        malpractice reasons that may occur<br />
        For the request to be carried out, it is enough to request the
        termination of the contract through the CONTROLLER's contact means,
        e-mail <a href="">diretoriaecosistemas@gmail.com</a>, providing the
        CONTROLLER with a period of up to 30 (thirty) days to make the
        information available on the electronic address registered by the USER.
      </p>
      <br />

      <h2>9. Right to Withdraw Consent</h2>

      <p>
        This consent may be withdrawn by the user, at any time, upon an express
        and clear request, via email
        <a href="">diretoriaecosistemas@gmail.com</a> or correspondence to the
        CONTROLLER, providing the CONTROLLER a period of 30 (thirty) days to
        receive the request, adopt the appropriate measures and prove the
        exclusion at the same address as the request.
      </p>
    </div>
    <br />
  </div>
  <!-- <div class="tudo">
      <br>
    <div class="politica">
           ACCEPTANCE TERM FOR PROCESSING PERSONAL DATA<br>
        This document aims to register the free, informed and unambiguous manifestation by which the user agrees with the treatment
        of your personal data for a specific purpose, in accordance with Law No. 13.709 - General Law for the Protection of
        Personal Data (LGPD).<br>
        By accepting this term, the user consents and agrees that the company ECO Sistemas e Soluções, CNPJ nº
        08.951.144/0001-35, headquartered at Rua Taquari, 370 – Bairro Zona Nova – Capão da Canoa/RS, telephone (51) 36253486 –
        51 991672281 e-mail direcriaecosistemas@gmail, hereinafter referred to as Controller, take decisions regarding the
        processing your personal data, as well as processing your personal data, involving operations
        such as those referring to collection, production, reception, classification, use, access, reproduction, transmission,
        distribution, processing, archiving, storage, elimination, evaluation or control of information,
        modification, communication, transfer, dissemination or extraction.<br>
        Personal data
        The Controller is authorized to make decisions regarding the processing and to carry out the processing of the following data
        Holder's personal:  Full name.  Personal email address  Maxivel registration  Distributor ID and Link  Distributor affiliate registrations
        Purposes of Data Processing
        The processing of personal data listed in this term has the following purposes:  Enable the Controller to identify and contact the owner for relationship purposes.  Enable the Controller to give access to the APP CRM Win system for data import and export
        for ADMIN CRM Win platform.<br>
        Data Sharing
        The Controller is authorized to share the Owner's personal data with other data processing agents,
        if necessary for the purposes listed in this term, subject to the principles and guarantees established by
        Law No. 13.709.<br>
        Data Security
        The Controller is responsible for maintaining security, technical and administrative measures capable of protecting
        personal data from unauthorized access and accidental or unlawful situations of destruction, loss, alteration,
        communication or any form of inappropriate or unlawful treatment.<br>
        Pursuant to art. 48 of Law No. 13.709, the Controller will notify the Holder and the National Protection Authority
        of Data (ANPD) the occurrence of a security incident that may cause relevant risk or damage to the Holder.<br>
        End of Data Processing
        The Controller may maintain and process the Holder's personal data throughout the period of his/her user registration
        APP CRM Win system and ADMIN CRM Win platform relevant to the purposes listed in this term.<br>
        Anonymized personal data, without the possibility of association with the individual, may be kept for a period
        Undefined.<br>
        The Holder may request via e-mail or correspondence to the Controller, at any time, that the
        Non-anonymized personal data of the Holder<br>
        Rights of the Holder
        The Holder has the right to obtain from the Controller, in relation to the data processed by him, at any time and through
        request: I - confirmation of the existence of treatment; II - access to data; III - correction of incomplete, inaccurate data
        or outdated; IV - anonymization, blocking or deletion of unnecessary, excessive or processed data
        non-compliance with the provisions of Law No. 13.709; V - portability of data to another service or product provider,
        upon express request, in accordance with the regulations of the national authority, observing the secrets
        commercial and industrial; VI - deletion of personal data processed with the consent of the holder, except in the cases
        provided for in art. 16 of Law No. 13.709; VII - information on public and private entities with which the controller performed
        shared use of data; VIII - information on the possibility of not providing consent and on the
        consequences of denial; IX - revocation of consent, pursuant to § 5 of art. 8 of Law No. 13.709.<br>
        Right of Withdrawal of Consent
        This consent may be revoked by the user, at any time, upon request via email or
        correspondence to the Controller.<br><br>

        TERMO DE ACEITE PARA TRATAMENTO DE DADOS PESSOAIS <br>
    Este documento visa registrar a manifestação livre, informada e inequívoca pela qual o usuário concorda com o tratamento 
    de seus dados pessoais para finalidade específica, em conformidade com a Lei nº 13.709 – Lei Geral de Proteção de 
    Dados Pessoais (LGPD).<br> 
    Ao aceitar o presente termo, o usuário consente e concorda que a empresa ECO Sistemas e Soluções, CNPJ nº 
    08.951.144/0001-35, com sede na Rua Taquari, 370 – Bairro Zona Nova – Capão da Canoa/RS, telefone (51) 36253486 – 
    51 991672281 e-mail diretoriaecosistemas@gmail, doravante denominada Controlador, tome decisões referentes ao 
    tratamento de seus dados pessoai, bem como realize o tratamento de seus dados pessoais, envolvendo operações 
    como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, 
    distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, 
    modificação, comunicação, transferência, difusão ou extração. <br>
    Dados Pessoais 
    O Controlador fica autorizado a tomar decisões referentes ao tratamento e a realizar o tratamento dos seguintes dados 
    pessoais do Titular:  Nome completo.  Endereço de e-mail pessoal  Cadastro Maxinivel  ID e Link de distribuidor  Cadastros de afiliados distribuidores 
    Finalidades do Tratamento dos Dados 
    O tratamento dos dados pessoais listados neste termo tem as seguintes finalidades:  Possibilitar que o Controlador identifique e entre em contato com o titular para fins de relacionamento.  Possibilitar que o Controlador possa dar acesso ao sistema APP CRM Win para importação e exportação de dados 
    para plataforma ADMIN CRM Win.<br> 
    Compartilhamento de Dados 
    O Controlador fica autorizado a compartilhar os dados pessoais do Titular com outros agentes de tratamento de dados, 
    caso seja necessário para as finalidades listadas neste termo, observados os princípios e as garantias estabelecidas pela 
    Lei nº 13.709. <br>
    Segurança dos Dados 
    O Controlador responsabiliza-se pela manutenção de medidas de segurança, técnicas e administrativas aptas a proteger 
    os dados pessoais de acessos não autorizados e de situações acidentais ou ilícitas de destruição, perda, alteração, 
    comunicação ou qualquer forma de tratamento inadequado ou ilícito. 
    Em conformidade ao art. 48 da Lei nº 13.709, o Controlador comunicará ao Titular e à Autoridade Nacional de Proteção 
    de Dados (ANPD) a ocorrência de incidente de segurança que possa acarretar risco ou dano relevante ao Titular. <br>
    Término do Tratamento dos Dados 
    O Controlador poderá manter e tratar os dados pessoais do Titular durante todo o período que seu cadastro de usuário 
    do sistema APP CRM Win e plataforma ADMIN CRM Win pertinentes ao alcance das finalidades listadas neste termo. 
    Dados pessoais anonimizados, sem possibilidade de associação ao indivíduo, poderão ser mantidos por período 
    indefinido. <br>
    O Titular poderá solicitar via e-mail ou correspondência ao Controlador, a qualquer momento, que sejam eliminados os 
    dados pessoais não anonimizados do Titular <br>
    Direitos do Titular 
    O Titular tem direito a obter do Controlador, em relação aos dados por ele tratados, a qualquer momento e mediante 
    requisição: I - confirmação da existência de tratamento; II - acesso aos dados; III - correção de dados incompletos, inexatos 
    ou desatualizados; IV - anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em 
    desconformidade com o disposto na Lei nº 13.709; V - portabilidade dos dados a outro fornecedor de serviço ou produto, 
    mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos 
    comercial e industrial; VI - eliminação dos dados pessoais tratados com o consentimento do titular, exceto nas hipóteses 
    previstas no art. 16 da Lei nº 13.709; VII - informação das entidades públicas e privadas com as quais o controlador realizou 
    uso compartilhado de dados; VIII - informação sobre a possibilidade de não fornecer consentimento e sobre as 
    consequências da negativa; IX - revogação do consentimento, nos termos do § 5º do art. 8º da Lei nº 13.709. <br>
    Direito de Revogação do Consentimento 
    Este consentimento poderá ser revogado pelo usuário, a qualquer momento, mediante solicitação via e-mail ou 
    correspondência ao Controlador. <br>
    </div>
    <br>
  </div> -->
</template>

<script>
export default {};
</script>


<style scoped>
/* .tudo{
   
}
.politica{
    border: 2px solid gray;
    padding: 8px;
    width: 80%;
    margin-left: auto;
    margin-right: auto; 
    text-align: justify;
} */

.politica {
  border: 2px solid gray;
  padding: 8px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: justify;
}

h1 {
  font-size: 20px;
  text-align: center;
  color: black;
}

h2 {
  font-size: 18px;
  color: black;
}
.sublinha {
  text-decoration: underline;
  display: inline;
}
.negrito {
  font-weight: bold;
}
</style>
