<template>
  <DashboardComponent>
    <div slot="slot-pages" class="content-pages">
      <div class="container fluid">
        <br />
        <h1>Editar Usuario</h1>
        <br />
        <form @submit.prevent="salvar()">
          <div class="form-group">
            <label class="col-md-2 control-label" for="Nome"
              >Usuario<h11>*</h11></label
            >
            <div class="col-md-8">
              <input
                id="userAdmin"
                placeholder=""
                class="form-control input-md"
                v-model="usuario.usuario"
                required=""
                type="text"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-2 control-label" for="Nome"
              >Nome<h11>*</h11></label
            >
            <div class="col-md-8">
              <input
                id="userAdmin"
                placeholder=""
                class="form-control input-md"
                v-model="usuario.nome"
                required=""
                type="text"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1">Nova Senha</label>
            <div class="col-md-8">
              <input
                type="password"
                class="form-control"
                v-model="usuario.password"
                id="adminPassword"
                placeholder="Password"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1">Confirme a Nova Senha</label>
            <div class="col-md-8">
              <input
                type="password"
                class="form-control"
                id="adminPasswordConfirm"
                placeholder="Password"
              />
            </div>
          </div>
          <br />
          <div style="" class="row">
            <div class="col">
              <button type="submit" class="buttonSave">Salvar</button>
            </div>
            <div class="col">
              <router-link :to="{ name: 'usuarios' }"
                ><button class="buttonCancel" role="button">
                  Voltar
                </button></router-link
              >
            </div>
            <div class="col"></div>
          </div>
        </form>
        <br />
      </div>
    </div>
  </DashboardComponent>
</template>


<script>
import { mapState, mapActions } from "vuex";
import DashboardComponent from "../Dashboard/Dashboard.vue";
export default {
  name: "EditUsuarioComponent",
  components: {
    DashboardComponent,
  },
  data() {
    return {
      id_admin: this.$route.params.id,
    };
  },

  computed: {
    ...mapState("usuarios", ["usuario"]),
  },

  methods: {
    ...mapActions("usuarios", ["getUsuario", "updateUsuario"]),

    salvar() {
      this.updateUsuario(this.usuario);
      this.getUsuario();
      this.$fire({
        title: "Sucesso",
        text: "Distribuidor editado com sucesso",
        type: "success",
        timer: 5000,
      }).then((r) => {
        console.log(r.value);
      });
      this.$router.push({ name: "usuarios" });
    },
  },
  mounted() {
    this.getUsuario(this.$route.params.id);
  },
};
</script>


<style scoped>
.buttonSave {
  height: 40px;
  width: 100%;
  border-radius: 4px;
  font-weight: bold;
  background-color: #ffce40;
  color: white;
  border: none;
}
.buttonCancel {
  height: 40px;
  width: 100%;
  border-radius: 4px;
  font-weight: bold;
  background-color: rgb(67, 192, 255);
  color: white;
  border: none;
}
</style>