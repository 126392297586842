<template>
  <DashboardComponent>
    <div slot="slot-pages" class="content-pages">
      <article>
        <div class="container-fluid">
          <!--USAR ROUTER LINK-->
          <div class="row">
            <div class="col">
              <a class="one">
                <router-link :to="{ name: 'clientesMensagens' }">
                  <div class="card">
                    <div class="card-body importados">
                      <h5 class="card-title">Mensagens Clientes</h5>
                      <p class="card-text">{{ statusPendente.length }}</p>
                    </div>
                  </div>
                </router-link>
              </a>
            </div>
            <!--USAR ROUTER LINK-->
            <div class="col">
              <a class="one">
                <router-link :to="{ name: 'analise' }">
                  <div class="card">
                    <div class="card-body clientes">
                      <h5 class="card-title">Em Analise</h5>
                      <p class="card-text">{{ statusAnalise.length }}</p>
                    </div>
                  </div>
                </router-link>
              </a>
            </div>
            <div class="col">
              <a class="one">
                <router-link :to="{ name: 'producao' }">
                  <div class="card">
                    <div class="card-body mensal">
                      <h5 class="card-title">Produção</h5>
                      <p class="card-text">{{ statusProducao.length }}</p>
                    </div>
                  </div>
                </router-link>
              </a>
            </div>
            <div class="col">
              <a class="one">
                <router-link :to="{ name: 'concluido' }">
                  <div class="card">
                    <div class="card-body ativos">
                      <h5 class="card-title">Concluidas</h5>
                      <p class="card-text">{{ statusConcluido.length }}</p>
                    </div>
                  </div>
                </router-link>
              </a>
            </div>
          </div>
        </div>
      </article>
    </div>
  </DashboardComponent>
</template>

<script>
import DashboardComponent from "../Dashboard/Dashboard.vue";
//import distribuidores from '../../../services/Distribuidor';
//import clientes from '../../../services/Cliente'
import { http } from "../../../http/index";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    DashboardComponent,
  },

  data() {
    return {
      clientesMensagem: [],
      statusPendente: [],
      statusAnalise: [],
      statusProducao: [],
      statusConcluido: [],
    };
  },

  computed: {
    ...mapState("distribuidores", [
      "distribuidores",
      "distribuidoresMensal",
      "distribuidoresAtivosTotal",
      "distriTotal",
    ]),
    ...mapState("clientes", ["clientes"]),

    // ...mapGetters([
    //     store.getters.doneTodos
    // ]),
  },
  created() {
    // this.getAllClientes();
    // this.getDistribuidores();
    // this.getDistribuidoresMensal();
    this.getMensagem();
    this.getUsuarios();
    //this.getDistribuidoresAtivosTotal();
    //this.getByUf('all');

    //inicinado a estancia dos metodos
    // this.totalClientes();
    // this.totalDistribuidores();
    // this.totalDistribuidoresMensal();
  },
  methods: {
    ...mapActions("distribuidores", [
      "getDistribuidores",
      "getDistribuidoresMensal",
      "getDistribuidoresAtivos",
      "getDistribuidoresAtivosTotal",
      "getByUf",
    ]),
    ...mapActions("clientes", ["getAllClientes"]),
    ...mapActions("usuarios", ["getUsuarios"]),

    getFiltraMensagem(){
        let mensagem = this.clientesMensagem;
        //console.log(mensagem);

        for (let i = 0; i < mensagem.length; i++) {

            if (mensagem[i].status == "pendente") {
              this.statusPendente.push(mensagem[i]);
            }
            if (mensagem[i].status == "Em analise") {
               this.statusAnalise.push(mensagem[i]);
            }
            if (mensagem[i].status == "Produção") {
               this.statusProducao.push(mensagem[i]);
            }
            if (mensagem[i].status == "concluido") {
               this.statusConcluido.push(mensagem[i]);
            }
        }



    },

    getMensagem() {
      http
        .get("/mensagens")
        .then((response) => {
          let mensagem = response.data;
          this.clientesMensagem = mensagem.result;
        })
        .finally(() => {
          this.getFiltraMensagem();
        });
    },
  },
};
</script>

<style scoped>
.br {
  display: none;
}
article {
  margin-top: 50px;
}
.legenda-container {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
.col-2 {
  display: contents;
}
h2 {
  margin-right: 20px;
}
.cor1 {
  margin-left: 50px;
  margin-right: 10px;
  width: 50px;
  height: 50px;
  background: linear-gradient(to right, #01a9ac, #01dbdf);
  float: left;
}
.cor2 {
  margin-right: 10px;
  width: 50px;
  height: 50px;
  background: linear-gradient(to right, #0ac282, #0df3a3);
  float: left;
}
.cor3 {
  margin-right: 10px;
  width: 50px;
  height: 50px;
  background: linear-gradient(to right, #fe5d70, #fe909d);
  float: left;
}
.cor4 {
  margin-right: 10px;
  width: 50px;
  height: 50px;
  background: linear-gradient(to right, #fe9365, #feb798);
  float: left;
}
h2 {
  font-size: 18px;
  text-align: left;
  padding-top: 13px;
}

.importados {
  /*      background: white; */
  border: 2px solid #000000;

  /*      background: linear-gradient(to right,#049497,#01dbdf); */
  background: linear-gradient(to right, #01a9ac, #01dbdf);
}

.ativos {
  /*  background: white; */
  border: 2px solid #0df3a3;

  /*     background: linear-gradient(to right,#0cab74,#0df3a3); */
  background: linear-gradient(to right, #0ac282, #0df3a3);
}
.clientes {
  /*        background: white; */
  border: 2px solid #fe909d;

  /*   background: linear-gradient(to right,#d15765,#fe909d); */
  background: linear-gradient(to right, #fe5d70, #fe909d);
}
.mensal {
  /*     background: white; */
  border: 2px solid #feb798;

  /*      background: linear-gradient(to right,#d37f5b,#feb798); */
  background: linear-gradient(to right, #fe9365, #feb798);
}
.content-pages {
  padding: 1px;
}

.card-title {
  font-size: 30px;
}

a:link {
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}
a.one:visited {
  color: white;
}
h1 {
  color: rgb(82, 58, 50);
  text-align: center;
  margin-right: 250px;
}
article {
  height: 100%;
}

.col {
  text-align: center;
}
.card-text {
  font-size: 50px;
  color: black;
}
.card-title {
  padding-top: 1px;
  color: black;
}
.card-body {
  border-radius: 12px;
  width: 330px;
  height: 150px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}
h5,
p {
  color: rgb(0, 0, 0) !important;
}
.card {
  background: black;
  border: none;
  width: 100%;
  height: 100%;
  margin: 10px 0px;
  color: #888888;
  font-size: 25px;
  border-radius: 12px;
}
@media (max-width: 1600px) {
  .br {
    display: block !important;
  }
}

@media (max-width: 1360px) {
  body {
    background: red;
  }
  /*       .br{
    display:none;
}  */
  .card-body {
    border-radius: 12px;
    width: 400px;
    height: 230px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  }
  .legenda-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }
  .cor1 {
    margin-left: 0;
  }
  .cor {
    margin-right: 0;
  }
  h2 {
    margin-right: 0;
  }
}

@media (max-width: 1180px) {
  .legenda-container {
    display: flex;
    flex-wrap: wrap;
  }
  .cor1 {
    margin-left: 0;
  }
  .cor {
    margin-right: 0;
  }
  h2 {
    margin-right: 0;
  }
  .row {
    padding-left: 5px;
  }

  .card {
    width: 100%;
  }
  .card-body {
    text-align: center;
  }
}
@media (max-width: 550px) {
  .legenda-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }
  .card-body {
    border-radius: 12px;
    width: 300px;
    height: 230px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  }
}
@media (max-width: 450px) {
  .card-body {
    border-radius: 12px;
    width: 250px;
    height: 230px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  }
}
@media (max-width: 350px) {
  .card-body {
    border-radius: 12px;
    width: 220px;
    height: 230px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  }
}
@media (max-width: 310px) {
  .card-body {
    border-radius: 12px;
    width: 200px;
    height: 230px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  }
}
</style>