    <template>
  <DashboardComponent>
    <div slot="slot-pages" class="content-pages">
      <div class="container fluid">
        <br />
        <h1>Cadastro de Usuarios</h1>
        <br />
        <form @submit.prevent="salvar">
          <div class="form-group">
            <label class="col-md-2 control-label" for="Nome"
              >Usuario<h11>*</h11></label
            >
            <div class="col-md-8">
              <input
                id="userAdmin"
                name="userAdmin"
                placeholder=""
                class="form-control input-md"
                v-model="admin.usuario"
                required=""
                type="text"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-2 control-label" for="Nome"
              >Nome<h11>*</h11></label
            >
            <div class="col-md-8">
              <input
                id="userAdmin"
                name="nomeAdmin"
                placeholder=""
                class="form-control input-md"
                v-model="admin.nome"
                required=""
                type="text"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1">Senha</label>
            <div class="col-md-8">
              <input
                type="password"
                class="form-control"
                v-model="admin.password"
                id="adminPassword"
                placeholder="Password"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1">Confirme a Senha</label>
            <div class="col-md-8">
              <input
                type="password"
                class="form-control"
                id="adminPasswordConfirm"
                placeholder="Password"
              />
            </div>
          </div>
          <br />
          <div style="" class="row">
            <div class="col">
              <button type="submit" class="buttonSave">Salvar</button>
            </div>
            <div class="col">
              <router-link :to="{ name: 'usuarios' }"
                ><button class="buttonCancel" role="button">
                  Voltar
                </button></router-link
              >
            </div>
            <div class="col"></div>
          </div>
        </form>
        <br />
      </div>
    </div>
  </DashboardComponent>
</template>

<script>
import DashboardComponent from "../Dashboard/Dashboard.vue";
import usuario from "../../../services/Usuario";

export default {
  name: "UsuarioComponent",
  components: {
    DashboardComponent,
  },
  data() {
    return {
      admin: {
        usuario: "",
        password: "",
        nome: "",
      },
    };
  },

  methods: {
    limpar() {
      document.getElementById("form").reset();
    },
    salvar() {
      usuario.salvar(this.admin).then((response) => {
        if (response.status == 201 || response.status == 200) {
          this.$router.push({ name: "usuarios" });
          this.$fire({
            title: "Sucesso",
            text: "Usuario cadastrado com sucesso",
            type: "success",
            timer: 5000,
          }).then((r) => {
            console.log(r.value);
          });
        } else if (response.status == 500) {
          alert("falha ao salvar");
        } else {
          alert("falha");
        }
      });
    },
  },
};
</script>

    <style scoped>
.buttonSave {
  height: 40px;
  width: 100%;
  border-radius: 4px;
  font-weight: bold;
  background-color: #ffce40;
  color: white;
  border: none;
}
.buttonCancel {
  font-weight: bold;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  background-color: rgb(67, 192, 255);
  border: none;
  color: white;
}
</style>

