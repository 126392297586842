import { http } from "../http/index";

export default{
    listar:()=>{
        return http.get('clientes')
    },

    listarByDistribuidor:(distribuidor)=>{
        return http.get(`aclientes/distribuidor=${distribuidor}`)
    },

    count:()=>{
        return http.get('clientes/count')
    }
}