import maxinivel from '../../../services/Maxinivel'

export default {
    namespaced: true,
    state: {
       maxinivel:[],
       
        },
    getters: {
       
    },
    mutations:{
        SET_DISTRIBUIDORES_MAXINIVEL(state, data){
            state.maxinivel = data;
        },
       
    },
    actions:{
        getDistribuidoresMaxinivel({commit}){
            maxinivel.listarMaxinivel().then(response => {
                commit('SET_DISTRIBUIDORES_MAXINIVEL', response.data)
            }).catch(error =>{
                console.log(error);
                alert("opa, algo deu errado, tente novamente")
            })           
        },
    },
}