<template>
<DashboardComponent>
    <div slot="slot-pages" class="content-pages">
      <div v-if="check">
        <div class="row">
          <div class="col"></div>
          <div class="col"><Spinner></Spinner></div>
          <div class="col"></div>
        </div>
      </div>
      <div v-else>
        <div class="container-fluid">
          <br /><br />
          <div class="titulo">
            <h2>Mensagens de Clientes</h2>
          </div>
          <br /><br />
          <div class="row">
            <div class="col">
              <select
                class="form-select"
                aria-label="Default select example"
                v-model="empresaSelecionada"
              >
                <option disabled value>Selecione</option>
                <option v-for="cliente in empresas" :key="cliente.id">
                  {{ cliente }}
                </option>
              </select>
            </div>
            <div class="col">
              <button class="buttonImprimir" @click="filtroCliente">
                Filtrar
              </button>
            </div>
            <div class="col"></div>
            <div class="col"></div>
          </div>
          <br />
          <div
            class="card"
            v-for="mensagem in clientsFilt"
            :key="mensagem.mensagemID"
          >
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h3 class="data">
                    Status:
                    <div class="dados">{{ mensagem.status }}</div>
                  </h3>
                </div>
                <div class="col">
                  <h3 class="data">
                    Data:
                    <div class="dados">{{ mensagem.data_mensagem }}</div>
                  </h3>
                </div>
                <div class="col">
                  <h3 class="data">
                    Cliente:
                    <div class="dados">{{ mensagem.empresa }}</div>
                  </h3>
                </div>
                <div class="col"></div>
                <div class="col">
                  <h3 class="data">
                    <button
                      class="buttonStatusCom"
                      @click="mudaStatus(mensagem.id)"
                    >
                      Concluido
                    </button>
                  </h3>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <h3 class="data">
                    Mensagem:<br />
                    <div class="dados">{{ mensagem.texto }}</div>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardComponent>
</template>

<script>
import DashboardComponent from "../Dashboard/Dashboard.vue";
//import {mapState, mapActions} from 'vuex';
import { http } from "../../../http/index";
//const { jsPDF } = require("jspdf");
import Spinner from "../Dashboard/components/Spinner.vue";
import "jspdf-autotable";
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
const Swal = require("sweetalert2");

export default {
  
  components: {
    DashboardComponent,
    Spinner
  },
    data() {
    return {
      titulo: "Lista geral de clientes",
      filterClie: [],
      clientsFilt: [],
      clientsTotal: [],
      check: true,
      empresas: [],
      empresasTotal: [],
      empresaSelecionada: "",
    };
  },

  computed: {},
  mounted() {
    //this.getAllClientes();
    //this.filterDistribuidores();
  },
  created() {
    this.getMensagem();
  },
  methods: {
    mudaStatus(id) {
      //this.check = false;
      let status = "concluido";
      http
        .put(`status/update/${id}`,{
          status: status
        })
        .then(() => {})
        .finally(() => {
          
          Swal.fire({
            position: "top-center",
            icon: "success",
            title: "Status mudado com sucesso",
            showConfirmButton: false,
            timer: 1500,
          });
          //this.check = true;
          this.getMensagem();

        });

    },

    filtroCliente() {
      console.log(this.empresaSelecionada);
      if (this.empresaSelecionada) {
        this.clientsFilt = [];
        for (let i = 0; i < this.clientsTotal.length; i++) {
          console.log(i);
          if (this.empresaSelecionada == this.clientsTotal[i].empresa) {
            this.clientsFilt.push(this.clientsTotal[i]);
          }
        }
        console.log(this.clientsFilt);
      }
    },

    

    getMensagem() {
      http
        .get("/mensagens")
        .then((response) => {
          let mensagem = response.data;
          this.clientsFilt = mensagem.result;
          this.clientsTotal = mensagem.result;
          console.log(this.clientsFilt);
        })
        .finally(() => {
            let mensagens = this.clientsFilt;
            this.clientsFilt = [];
            for (let i = 0; i < mensagens.length; i++) {
              if (mensagens[i].status == "Produção") {
                this.clientsFilt.push(mensagens[i])
              }
              
            }
          console.log(this.clientsFilt);
          this.converteData();
        });
    },

    converteData() {
      for (let i = 0; i < this.clientsFilt.length; i++) {
        let corte = this.clientsFilt[i].data.split(" ");

        let divisao = corte[0].split("-");
        let dataCerto = `${divisao[2]}-${divisao[1]}-${divisao[0]} `;

        this.clientsFilt[i].data_mensagem = dataCerto;
        this.empresasTotal.push(this.clientsFilt[i].empresa);
      }
      this.clientsFilt.reverse();

      let unico = this.empresasTotal.filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      });
      this.empresas = unico;

      console.log(this.empresas);

      this.check = false;
    },
  },
};
</script>

<style scoped>
.content-pages {
  padding: 1px;
}
.titulo {
  text-align: center;
}
article {
  background-color: #1f1f1f;
  height: 100%;
}
h2 {
  text-align: center;
  font-size: 38px;
}
p {
  font-size: 18px;
}
.card {
  background-color: rgb(27, 31, 30);
}
.card-body {
  background-color: rgb(27, 31, 30);
  border: 5px solid rgb(253, 253, 253);
  margin-top: 8px;
}
select {
  height: 40px;
  width: 100%;
}

.data {
  color: #fe9365;
  font-size: 20px;
  display: flex;
}
.dados {
  padding-left: 5px;
  font-size: 20px;
  color: wheat;
}
.buttonImprimir {
  height: 40px;
  width: 100%;
  border-radius: 4px;
  background-color: rgb(67, 192, 255);
  color: white;
  border: none;
}
.buttonStatusCom {
  height: 40px;
  width: 100%;
  border-radius: 4px;
  background-color: #0ac282;
  color: white;
  border: none;
}
select {
  height: 35px;
  width: 100%;
}
</style>