<template>
  <DashboardComponent>
    <div slot="slot-pages" class="content-pages">
      <article>
        <div class="container fluid">
          <h1>Usuarios</h1>
          <br />
          <div class="row">
            <div class="col"></div>
            <div class="col"></div>
            <div class="col">
              <router-link :to="{ name: 'cadastrousuarios' }"
                ><button type="button" class="buttonNew">
                  Novo Usuario
                </button></router-link
              >
            </div>
          </div>
          <br />
          <div class="table-responsive">
            <table class="table" id="tabelaUsuario">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Nome</th>
                  <th scope="col">Usuario</th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="usuario in usuarios" :key="usuario.id_admin">
                  <td>{{ usuario.id_admin }}</td>
                  <td>{{ usuario.nome }}</td>
                  <td>{{ usuario.usuario }}</td>
                  <td>
                    <button
                      class="delButton"
                      @click="destroyUsuario(usuario.id_admin)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="del"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
                        />
                        <path
                          fill-rule="evenodd"
                          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                        />
                      </svg>
                    </button>
                  </td>

                  <td>
                    <router-link
                      :to="{
                        name: 'editusuario',
                        params: { id: usuario.id_admin },
                      }"
                      ><button class="buttonEdit">Editar</button></router-link
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </article>
    </div>
  </DashboardComponent>
</template>
<script>
</script>
<script>
import DashboardComponent from "../Dashboard/Dashboard.vue";
import { mapState, mapActions } from "vuex";
import Usuario from "../../../services/Usuario";
const Swal = require("sweetalert2");
import axios from 'axios';
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
export default {
  name: "UsuarioComponent",
  components: {
    DashboardComponent,
  },

  data(){
    return{
      user: [],
    }
  },

  methods: {
    ...mapActions("usuarios", ["getUsuarios"]),


    teste(){
      axios.get('https://api.ecoteste.crmwin.eco.br/public/api/distribuidores')
           .then(response => {
              this.user = response;
              console.log(this.user);
              console.log('testanto');
           }).catch(erro => console.log (erro))
    },

    destroyUsuario(id_distribuidor) {
      Swal.fire({
        title: "Atenção",
        text: "Vocẽ deseja realmente inativar esse usuario",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, desejo inativar",
      }).then((result) => {
        if (result.isConfirmed) {
          Usuario.inativar(id_distribuidor)
            .then((response) => {
              if (response.status == 200) {
                this.getUsuarios(),
                  this.$fire({
                    title: "Sucesso",
                    text: "Usuario inativado com sucesso",
                    type: "success",
                    timer: 5000,
                  }).then((r) => {
                    console.log(r.value);
                  });
              } else {
                alert("algo deu errado, tente novamente");
              }
            })
            .catch(console.error());
        }
      });
    },
  },

  created(){
      this.teste();
      this.getUsuarios();
     $(document).ready(function () {
      $.noConflict();
      var table = $("#tabelaUsuario").DataTable({
        "processing": true,
        "language": {
          url: "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Portuguese-Brasil.json",
        },
        "columnDefs": [
          { orderable: false, targets: 3 },
          { orderable: false, targets: 4 },
        ],
        "order": [[1, "asc"]],
        "dom": "lrtip",
      });
      $("#filterbox").on("keyup", function () {
        table.search(this.value).draw();
      });
    });

  },
  mounted() {
    
  },
  computed: {
    ...mapState("usuarios", ["usuarios"]),
  },
};
</script>





<style scoped>
.content-pages {
  padding: 1px;
}

article {
  background-color: #1f1f1f;
  height: 100%;
}

h1 {
  text-align: center;
}
td {
  background-color: #1f1f1f;
  color: white;
}
thead {
  font-size: 18px;
  text-align: center;
  background-color: rgb(156, 153, 153);
}
tbody {
  font-size: 17px;
  text-align: center;
}
.buttonNew {
  font-weight: bold;
  height: 42px;
  width: 100%;
  border-radius: 4px;
  background-color: #ffce40;
  border: none;
  color: white;
}
.buttonEdit {
  font-weight: bold;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  background-color: rgb(39 164 227);
  border: none;
  color: rgb(255 255 255);
}
.delButton {
  border: none !important;
  background-color: #1f1f1f !important;
}
.del {
  color: red;
}

@media (max-width: 1100px) {
  .buttonEdit {
    height: 40px;
    width: 90px;
    border-radius: 4px;
    border: none;
    font-size: 12px;
  }
}
</style>