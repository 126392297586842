import { http } from "../http/index";

export default{
    listar:()=>{
        return http.get('users')
    },
    salvar:(admin)=>{
        return http.post('makeUser' , admin)
    },
    inativar:(id)=>{
        return http.get(`user/inative/${id}`)
    },
    byId:(id_admin)=>{
        return http.get(`user/${id_admin}`)
    },
    editar:(admin)=>{
        return http.put('user/update', admin)
    },

}