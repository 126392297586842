
import clientes from '../../../services/Cliente'
export default {
    namespaced: true,
    state: {
        clientes: [],
        },
    getters: {
     
        },
    mutations:{
        SET_CLIENTES(state, data){
            state.clientes = data;
        },
      
    },
    actions:{
        getAllClientes({commit}){
            clientes.listar().then(response => {
                commit('SET_CLIENTES', response.data)
            }).catch(error =>{
                console.log(error);
                alert("opa, algo deu errado, tente novamente")
            })           
        },
    },
    
}