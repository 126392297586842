
import distribuidores from '../../../services/Distribuidor'
import clientes from '../../../services/Cliente'
import alert from "vue-simple-alert";

export default {
    namespaced: true,
    state: {
        distribuidores: [],
        distribuidor : null,
        distribuidorClients : [],
        distribuidoresMensal: [],
        distribuidoresUf: [],
        distribuidoresAtivos:null,
        distribuidoresAtivosTotal:[],
        idmaxi:[],
        

        },

    getters: {
       
    },
    mutations:{
        SET_DISTRIBUIDORES(state, data){
            state.distribuidores = data;
        },
        SET_DISTRIBUIDOR(state, data){
            state.distribuidor = data;
        },
        SET_DISTRIBUIDOR_CLIENTS(state,data){
            state.distribuidorClients = data;
        },
        SET_DISTRIBUIDORES_MENSAL(state, data){
            state.distribuidoresMensal = data;
        },
        SET_DISTRIBUIDORES_UF(state, data){
            state.distribuidoresUf = data;
        },
        SET_DISTRIBUIDORES_ATIVOS(state,data){
            state.distribuidoresAtivos = data;
        },
        SET_DISTRIBUIDORES_ATIVOS_TOTAL(state,data){
            state.distribuidoresAtivosTotal = data;
        },
    },
    actions:{
        
        getDistribuidores({commit}){
            distribuidores.listar().then(response => {
                commit('SET_DISTRIBUIDORES', response.data)
            }).catch(error =>{
                console.log(error);
                alert("opa, algo deu errado, tente novamente")
            })           
        },
        getDistribuidor({commit}, id_distribuidor){
            distribuidores.byId(id_distribuidor).then(response => {
                commit('SET_DISTRIBUIDOR', response.data)
            }).catch(error => {
                console.log(error);
            })
        },
        getDistribuidoresMensal({commit}){
            distribuidores.mensal().then(response =>{
                commit('SET_DISTRIBUIDORES_MENSAL', response.data)
            }).catch(error =>{
                console.log(error);
            })
        },
        getDistribuidoresAtivos({commit}){
            distribuidores.ativos().then(response =>{
                commit('SET_DISTRIBUIDORES_ATIVOS', response.data)
            }).catch(error =>{
                console.log(error);
            })
        },
        getDistribuidoresAtivosTotal({commit}){
            distribuidores.distribuidoresAtivos().then(response=>{
                commit('SET_DISTRIBUIDORES_ATIVOS_TOTAL', response.data)
            }).catch(error =>{
                console.log(error);
            })
        },

        getDistribuidorClients({commit}, id_distribuidor){
            clientes.listarByDistribuidor(id_distribuidor).then(response =>{
                commit('SET_DISTRIBUIDOR_CLIENTS', response.data)
            }).catch(error =>{
                console.log(error);
            })
        },
        getAllClients({commit}){
            clientes.listar().then(response=>{
                commit('SET_DISTRIBUIDOR_CLIENTS', response.data)
            }).catch(error =>{
                console.log(error)
            })
        },
        
        getByUf({commit}, uf){
            distribuidores.byUf(uf).then(response =>{
            console.log(response.data);
            commit('SET_DISTRIBUIDORES_UF', response.data)
        }).catch(error => {
            console.log(error);
        })
        },  
        updateDistribuidor({dispatch}, distribuidor){
            distribuidores.update(distribuidor).then(response =>{ 
                if(response.status == 200){
                    dispatch('getDistribuidores')
                    alert.fire({
                        title: "Sucesso",
                        text: "Distribuidor editado com sucesso",
                        type: "success",
                        timer: 5000
                    }).then(r => {
                      console.log(r.value);
                    });
                }
                
            }).catch(error =>{
                console.log(error);
                alert.fire({
                    title: "Erro",
                    text: `Ocorreu um erro tente novamente`,
                    type: "error",
                    timer: 5000
                  }).then(r => {
                    console.log(r.value);
                  });
                  return false;
            })
        },
        destroyDistribuidor({commit}, id_distribuidor){
            distribuidores.byId(id_distribuidor).then(response => {
                commit(' SET_DISTRIBUIDOR', response.data)
            }).catch(error => {
                console.log(error);
            })
        },
    
    },
    
}