import { http } from "../http/index";

export default{
    listar:()=>{
        return http.get('login')
    },
    login:(usuario)=>{
        return http.post('login', usuario)
    }
    
}