
import usuario from '../../../services/Usuario';

export default {
    namespaced: true,
    state: {
        usuarios: [],
        usuario : null,
        },
    mutations:{
        SET_USUARIOS(state, data){
            state.usuarios = data;
        },
        SET_USUARIO(state, data){
            state.usuario = data;
        }
    
    },
    actions:{
        getUsuarios({commit}){
            usuario.listar().then(response => {
                commit('SET_USUARIOS', response.data)
            }).catch(error =>{
                console.log(error);
                alert("opa, algo deu errado, tente novamente")
            })           
        },
        getUsuario({commit}, id_usuario){
            usuario.byId(id_usuario).then(response => {
                commit('SET_USUARIO', response.data)
            }).catch(error => {
                console.log(error);
            })
        },
        updateUsuario({dispatch}, usuarios){
            usuario.editar(usuarios).then(response =>{ 
                if(response.status == 200){
                    dispatch('getUsuarios')
                    this.$fire({
                        title: "Sucesso",
                        text: "Distribuidor editado com sucesso",
                        type: "success",
                        timer: 5000
                      }).then(r => {
                        console.log(r.value);
                      });
                }
                else{
                    this.$fire({
                        title: "Erro",
                        text: "Falha ao editar",
                        type: "error",
                        timer: 5000
                      }).then(r => {
                        console.log(r.value);
                      });
                }
            }).catch(error =>{
                console.log(error);
                this.$fire({
                    title: "Erro",
                    text: "Falha ao editar",
                    type: "error",
                    timer: 5000
                  }).then(r => {
                    console.log(r.value);
                  });
                
            })
        },
        destroyUsuario({commit}, id_usuario){
            usuario.byId(id_usuario).then(response => {
                commit('SET_USUARIO', response.data)
            }).catch(error => {
                console.log(error);
            })
        },  
    },
    
}