/*import Vue from "vue";
import Vuex from "vuex";
import distribuidoresModule from './modules/distribuidor';


Vue.use(Vuex);

export const store = new Vuex.Store({
  state : distribuidoresModule.state,
  mutations : distribuidoresModule.mutations,
  actions : distribuidoresModule.actions,
  getters : distribuidoresModule.getters,
});
*/
import Vue from "vue";
import Vuex from "vuex";
import distribuidoresModule from './modules/distribuidor';
//import { LOADING_SPINNER_SHOW_MUTATION } from "./modules/storeConstants";
import usuariosModule from "./modules/usuarios";
import loaderModule from './modules/loader';
import clientesModule from './modules/clientes';
import maxinivelModule from './modules/maxinivel';

Vue.use(Vuex);

const clientes = {
  namespaced: true,
  state: clientesModule.state,
  mutations: clientesModule.mutations,
  actions: clientesModule.actions,
  getters: clientesModule.getters
}

const distribuidores = {
  namespaced: true,
  state: distribuidoresModule.state,
  mutations: distribuidoresModule.mutations,
  actions: distribuidoresModule.actions,
  getters: distribuidoresModule.getters
}
const usuarios = {
  namespaced: true, 
  state: usuariosModule.state,
  mutations: usuariosModule.mutations,
  actions: usuariosModule.actions,
  getters: usuariosModule.getters
}
const maxinivel = {
  namespaced: true,
  state: maxinivelModule.state,
  mutations: maxinivelModule.mutations,
  actions : maxinivelModule.actions,
  getters: maxinivelModule.getters
}
const showLoading = {
  namespaced: true,
  state : loaderModule.state,
  mutations: loaderModule.mutations,
  actions: loaderModule.actions
}

export const store = new Vuex.Store({
  modules: {
    distribuidores : distribuidores,
    usuarios : usuarios,
    showLoading : showLoading,
    clientes : clientes,
    maxinivel:  maxinivel,
  },
  
});


